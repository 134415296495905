<script lang="ts" setup>
import {
  BlockCarouselManualFields,
  BlockCoverAnimationFields,
  BlockCoverNavigationFields,
  BlockFooterCTAFields,
  BlockImageTextFields,
  BlockQuoteCarouselFields,
  BlockSingleQuoteFields,
} from '~/utils/CmsBlockFields'

const { $directus, $readSingleton } = useNuxtApp()

const { data } = await useAsyncData('homepage', async () => {
  return $directus.request($readSingleton('homepage', {
    fields: ['*', {
      hero_video: ['*'],
      hero_video_mobile: ['*'],
      seo: ['*', {
        translations: ['*'],
      }],
      translations: ['*'],
      blocks: [
        'collection',
        'id',
        {
          item: {
            block_carousel_manual: BlockCarouselManualFields,
            block_cover_animation: BlockCoverAnimationFields,
            block_cover_navigation: BlockCoverNavigationFields,
            block_single_quote: BlockSingleQuoteFields,
            block_image_text: BlockImageTextFields,
            block_footer_cta: BlockFooterCTAFields,
            block_quote_carousel: BlockQuoteCarouselFields,
            block_simple_specification: BlockSimpleSpecificationFields,
          },
        },
      ],
    }],
  }))
})

useDirectusSEO(data?.value?.seo)
</script>

<template>
  <div>
    <HeroHome :data="(data as any)" />
    <div>
      <div v-for="block in data?.blocks" :key="block.id">
        <BlockPoolAnimation v-if="block.collection === 'block_cover_animation'" :data="block.item" />
        <BlockPoolNavigation v-if="block.collection === 'block_cover_navigation'" />
        <BlockSingleQuote v-if="block.collection === 'block_single_quote'" :data="block.item" />
        <BlockImageText v-if="block.collection === 'block_image_text'" :data="block.item" />
        <BlockCarouselManual v-if="block.collection === 'block_carousel_manual'" :data="block.item" />
        <BlockFooterCTA v-if="block.collection === 'block_footer_cta'" :data="block.item" />
        <BlockQuoteCarousel v-if="block.collection === 'block_quote_carousel'" :data="block.item" />
        <BlockSimpleSpecification v-else-if="block.collection === 'block_simple_specification'" :data="block.item" />
      </div>
    </div>
  </div>
</template>

<style>
.gradient-top-to-bottom {
  background: linear-gradient(to top, #000b1a 55%, transparent 100%);
}
</style>
